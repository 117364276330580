import React from 'react'
import BlockContent from "@sanity/block-content-to-react";
import urlBuilder from "@sanity/image-url"
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Head from '../components/head'
import Layout from '../components/layout'
import * as ResearchStyles from '../components/project.module.css'

export const query = graphql`
  {
    allSanityResearch(sort: {fields: _createdAt, order: DESC}) {
      edges {
        node {
          title
          titleImg {
            asset {
              gatsbyImageData(width:800)
            }
          }
          titleTxt
          description
          slug {
            current
          }
          tag {
            tagName
            slug {
              current
            }
          }
          img {
            asset {
                gatsbyImageData(width: 480)                
            }
          }
          _rawText
        }
      }
    }
  }
`;

const urlFor = source =>
  urlBuilder({ projectId: "frgqt3qn", dataset: "production" }).image(source);

  const serializer = {
    types: {
      image: props => (
        <figure>
          <img
            src={urlFor(props.node.asset)
              //.width(600)
              .url()}
            alt={props.node.alt}
          />
        </figure>
      )
    }
  }; 

const Research = ({ data }) => {
    //console.log( data );
    const artworkData = data.allSanityResearch.edges;

    return(
        <Layout>
            <Head title="Research"/>
            <h1>Research</h1>
            <ol className = { ResearchStyles.imagePanels } >
            
                {   
                  
                   artworkData.map(( { node }) => (  
                       <li key = { node.slug.current }>
                        <div className={ResearchStyles.imagePanelItem}>
                           <h3>{node.title}</h3>
                           <div className={ResearchStyles.tag}> 
                                {node.tag.map((item, index) => (
                                  <Link to={`/categories/${item.slug.current}`}>
                                      <span key={index}>{item.tagName}{(item.length - 1 !== index) && ', '} </span>
                                  </Link>
                                  ))}
                          </div>
    
                           <div className = { ResearchStyles.imagePanelImg}>
                           <Link to={`/research/${node.slug.current}`}>
                                <GatsbyImage image = { node.titleImg.asset.gatsbyImageData } alt={ node.title} />
                               </Link> 
                           </div>
                           <p className={ResearchStyles.subtitle}> {node.titleTxt}</p>
                          </div> 
              
                       </li>
                   )) 
                }
            </ol>
        </Layout>
    )
}

export default Research